import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchStickyBar(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/sticky_bar/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStickyBar(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/sticky_bar", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteStickyBar(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/sticky_bar?id=${data.id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/sticky_bar/sticky_bar_status`, { id: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
